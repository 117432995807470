import React from 'react'

export default class Widget extends React.Component {
    constructor(props) {
        super(props);
        this.spanStyles = {};

        if (props.colspan !== 1) {
            this.spanStyles.gridColumn
            = `span ${props.colspan}`;
          }
          if (props.rowspan !== 1) {
            this.spanStyles.gridRow
            = `span ${props.rowspan}`;
          }
    }
    
    render() {
        return (
            <div className="Widget" style={this.spanStyles}>
                <div className="header">
                    <h2>{this.props.heading}</h2>
                </div>
                <div className="content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}