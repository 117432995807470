import axios from 'axios'

const API_URL = 'https://dogehaus-backend.herokuapp.com/'
// const API_URL = 'https://127.0.0.1:8000/'

const register = (username, email, password) => {
    return axios.post(API_URL + "signup", {
        username,
        email,
        password,
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "api-token-auth/", {
            username,
            password,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const update_pw = (oldpass, newpass, confirm_newpass) => {
    return axios
        .post(API_URL + "update-credentials/", {
            oldpass,
            newpass,
            confirm_newpass
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
        })
}

const logout = () => {
    localStorage.removeItem("user");
};

export default {
    register,
    update_pw,
    login,
    logout,
};
