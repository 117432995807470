import React, {useState, useEffect} from 'react';
import ccxt from 'ccxt'

// const histData = kucoin.fetch_ohlcv(du, '1d').then(
//   x => {return x;}
// )

const toDate = (x) => {
  var d = new Date(0);
  d.setUTCSeconds(x/1000);
  return d;
}

const formatMmDdYyyy = (rawDate) => {
    var date = toDate(rawDate);
    var day = (date.getUTCDate()).toString();
    var month = (date.getUTCMonth() + 1).toString();
    var year = date.getUTCFullYear().toString()

    return `${month}/${day}/${year}`
}

export default function HistoricData() {
    const [dogeHist, setDogeHist] = useState([])

    useEffect(() => {
        histData()
    }, [])

    const kucoin = new ccxt['kucoin']({
        'enableRateLimit': true,
        'proxy': 'https://doge-cors.herokuapp.com/',
    });
    const du = 'DOGE/USDT';

    const histData = () => {
        kucoin.fetch_ohlcv(du, '1d').then(
            result => setDogeHist(result.slice(Math.max(result.length - 10, 0)))
        )

    };

    // console.log('dogeHist:')
    // console.log(dogeHist.map(x => formatMmDdYyyy(x[0])))
    
    const td_style = {
        'padding': '5px'
    }

    return (
        <div >    
            <table>
                <thead>
                    <tr><th key='blank'></th>
                        {dogeHist.map(t => (<th style={td_style} key={t[0]}>{formatMmDdYyyy(t[0])}</th>))}
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={td_style} key="Total">Total</td>
                    {dogeHist.map(c => <td style={td_style} key={c[1]}>${(17454 * c[1]).toFixed(2)}</td>)}
                </tr>
                <tr>
                    <td style={td_style} key="each">Each Member</td>
                    {dogeHist.map(c => <td style={td_style} key={c[1]}>${(17454 * c[1] / 4).toFixed(2)}</td>)}
                </tr>
                </tbody>
            </table>
        </div>
    )
}

