import React from 'react';
import Widget from '../widget';
import Performance from '../performance';
import HistoricData from '../historicData';
import Browser from '../browser';
import ccxt from 'ccxt';

export default class Dashboard extends React.Component {

  render() {
    return (
      <div className="DashboardWidget">
        <Widget heading="Latest News" rowspan="2" >
          <Browser />
        </Widget>
        <Widget heading="Performance" colspan="3" rowspan="2">
          <Performance />
        </Widget>
        <Widget heading="Investor Contributions" colspan="3"  style={{
          
            overflow: 'hidden'
        }}>
          <HistoricData/>
        </Widget>
        <Widget heading="Futures">
          <p>Look at the big crystal balls on that guy</p>
        </Widget>
      </div>
    );
  }
}
