import React from 'react'
import ccxt from 'ccxt'
import TradingViewWidget, { Themes } from 'react-tradingview-widget'

export default class Performance extends React.Component {
  constructor() {
    super();

    this.state = {
      data: null,
      body_width: document.body.clientWidth
    };

    // window.addEventListener("resize", this.resize().bind(this));
  }

  // loadDoge() {
  //   let exchange = new ccxt.binance({
  //     'enableRateLimit': true
  //   });

  //   let since = exchange.milliseconds() - 86400000;

  //   exchange.fetchTrades('DOGE/USDT', since)
  //     .then(

  //     )

  //   // console.log(doge)
  //   return doge
  // }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <TradingViewWidget
          symbol="KUCOIN:DOGEUSDT"
          theme={Themes.DARK}
          locale="us"
          autosize
        />
      </div>
    )
  }
}

