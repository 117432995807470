import React from 'react';
import {ListItem} from '@material-ui/core';
import ccxt from 'ccxt';

const exchanges = ccxt.exchanges;

const exchangeClicked = (exc) => {
  console.log(exc);
  getDogeUsdFromExchange(exc);
  // getTickers(exc)
};

const getTickers = async (exc) => {
  const proxy = 'http://localhost:3000/';

  const exchange = new ccxt[exc]({
    'enableRateLimit': true,
    // 'proxy': proxy
  });

  try {
    const tickers = await exchange.fetch_tickers();
    console.log(tickers);
  } catch (error) {
    console.log(`encountered error: ${error}`);
  }
};

const getDogeUsdFromExchange = async (exc) => {
  const exchange = new ccxt[exc]({
    'enableRateLimit': true,
  });

  const doge = await exchange.fetch_ticker('DOGE/USDT');

  console.log(doge);
};

function Browser() {
  return (
    <div className='scroller' style={{height: '500px', overflow: 'scroll'}}>
      {exchanges.map((e) => <ListItem button onClick={() => exchangeClicked(e)} key={e}>{e}</ListItem>)}
    </div>
  );
}

export default Browser;
