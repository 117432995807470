import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/Dashboard.css';
import './styles/Widget.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navigation from './components/navigation';
import Dashboard from './components/dashboard';
import Login from './components/login';
import Profile from './components/profile'
import React, { useState } from 'react';
import { useSelector } from "react-redux";

// 
function App() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dashboardValidation = currentUser ?
    <Dashboard /> : <Login />;

  // if (!token) {
  //   return <Login setToken={setToken}/>
  // }
  // console.log(`process env = ${process.env.REACT_APP_NOT_SECRET_CODE}`)
  return (
    <div className="App">
      <BrowserRouter>
        <Navigation />
        <Switch>
          <Route path="/">
            {dashboardValidation}
          </Route>
          <Route path="/dashboard">
            {dashboardValidation}
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
