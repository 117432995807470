import React from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import Logo from '../../resources/dogehaus_logo_black.svg';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";



export default function Navigation() {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };
  
  const { user: currentUser } = useSelector((state) => state.auth);
  const LoginLogout = currentUser ? (
    <Nav.Link href="#logout" onClick={logOut}>Logout</Nav.Link>
  ) : (
    <Nav.Link href="login">Investor Login</Nav.Link>
  );

  
    return (
      <Navbar bg='light' variant='light'>
        <Navbar.Brand href="dashboard">
          <img src={Logo}
            height="40"
            className="d-inline-block align-top"
            alt="Dogehaus Cryptocapital Logo"
          />
        </Navbar.Brand>
        <Nav className="mr-auto" />
        <Nav>
          <Nav.Link href="dashboard">Home</Nav.Link>
          {/* <Nav.Link href="login">Investor Login</Nav.Link> */}
          {currentUser && <Nav.Link href="profile">Profile</Nav.Link>}
          {LoginLogout}
        </Nav>
      </Navbar>
    );
  }

